<template>
  <div class="add_teacher">
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="110px"
      >
        <el-form-item label="是否叠加:">
          <!-- 单选 -->
          <el-radio v-model="addFrom.isAdd" :label="1">是</el-radio>
          <el-radio v-model="addFrom.isAdd" :label="0">否</el-radio>
        </el-form-item>

        <el-form-item
          label="叠加活动:"
          prop="checkList"
          v-if="addFrom.isAdd == 1"
        >
          <el-checkbox-group v-model="addFrom.checkList">
            <el-checkbox label="1">邀请下单</el-checkbox>
            <el-checkbox label="2">邀请注册</el-checkbox>
            <el-checkbox label="3">消费得奖</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="活动规则详情">
          <VueUeditorWrap
            v-model="addFrom.rule"
            :config="myConfig"
          ></VueUeditorWrap>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <!-- <el-button type="primary" @click="goBack">返回</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { selectInviteBaseSet, modifyInviteBaseSet } from "../../api/invite.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度

        // serverUrl: "/api/config",
        // UEDITOR_HOME_URL: "/UEditor/",

        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        serverUrl: "/config", //打包
        maximumWords: "100000000",
      },
      addFrom: {
        isAdd: 1,
        addWay: "",
        rule: "",
        checkList: [],
      },
      //   checkList:[],
      addFromRules: {
        checkList: [
          { required: true, message: "请选择叠加活动", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getInviteBaseSet();
  },
  methods: {
    async getInviteBaseSet() {
      const { data } = await selectInviteBaseSet();
      this.addFrom = { ...this.addFrom, ...data.data };
      if (data.data.isAdd == 0) {
        this.addFrom.checkList = [];
      } else {
        this.addFrom.checkList = data.data.addWay;
      }
    },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        this.addFrom.checkList  = this.addFrom.checkList.sort((a, b) => a - b);
        this.addFrom.addWay = this.addFrom.checkList.join(",");
        // delete this.addFrom.checkList
        const { isAdd, addWay, rule } = this.addFrom;
        let parmas = {
          isAdd,
          addWay:isAdd ==0 ?'':addWay,
          rule,
        };
        const { data } = await modifyInviteBaseSet(parmas);
        if (data.code == 0) {
          this.$message.success(data.msg);
          this.getInviteBaseSet();
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>>